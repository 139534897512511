.auth-layout {
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    height: 100vh;
  }
  .ant-col:first-child {
    padding: 2rem;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (min-width: 991px) {
      padding: 3rem 5.635rem;
    }
  }
  .auth-container {
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      padding: 16px 0px;
      height: 100vh;
    }
    .auth-text-container {
      flex: 1;
      // min-height: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 60px;
      border-top-left-radius: 80px;
      border-bottom-right-radius: 80px;
      @media (max-width: 768px) {
        border-top-left-radius: 40px;
        border-bottom-right-radius: 40px;
      }
    }
    .auth-block-container {
      display: flex;
      flex: 1;
      min-height: 1px;
      // max-height: 90px;
      overflow: hidden;
      @media (max-width: 768px) {
        display: none;
      }
      .auth-block {
        flex: 1;
        min-width: 1px;
        // background-repeat: no-repeat;
        // background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
      }
      .circle {
        border-radius: 50%;
      }
    }
    .bblr {
      border-bottom-left-radius: 80px;
      @media (max-width: 768px) {
        border-bottom-left-radius: 40px;
      }
    }
    .bbrr {
      border-bottom-right-radius: 80px;
      @media (max-width: 768px) {
        border-bottom-right-radius: 40px;
      }
    }
    .btlr {
      border-top-left-radius: 80px;
      @media (max-width: 768px) {
        border-top-left-radius: 40px;
      }
    }
    .btrr {
      border-top-right-radius: 80px;
      @media (max-width: 768px) {
        border-top-right-radius: 40px;
      }
    }
  }
}
// .left-top-image {
//   background: url(../../assets/img/auth/left-top.svg) no-repeat;
//   position: absolute;
//   width: 154px;
//   height: 193px;
//   left: -144px;
//   top: -37px;
//   background-size: 100%;
// }

// .right-top-image {
//   background: url(../../assets/img/auth/right-top.svg) no-repeat;
//   width: 171px;
//   height: 265px;
//   background-size: 100%;
//   top: -48px;
//   right: -10px;
//   position: absolute;
// }

// .left-bottom-image {
//   background: url(../../assets/img/auth/left-bottom.svg) no-repeat;
//   position: absolute;
//   background-size: 100%;
//   width: 214px;
//   height: 282px;
//   left: -144px;
//   bottom: -48px;
// }

// .right-bottom-image {
//   background: url(../../assets/img/auth/right-bottom.svg) no-repeat;
//   background-size: 100%;
//   width: 190px;
//   height: 168px;
//   position: absolute;
//   @media (min-width: 991px) {
//     right: 0;
//     bottom: -48px;
//   }
// }
.auth-text-holder {
  position: relative;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
}
